import $ from "jquery";

export const fixedHeaderTable = () => {
  let tableHeader = document.querySelector(".table__fixed-header");
  if (tableHeader) {
    let header = tableHeader.querySelector("tr:first-child");
    let tableHeaderHeight = header.offsetHeight;
    let siteHeaderHeight = document.querySelector(".site-header").clientHeight;

    let setScrollTopPost = siteHeaderHeight + "px";

    let headerOffsetTop = $(".table__fixed-header tr:first-child").offset().top;
    let trueHeaderOffsetTop = headerOffsetTop - siteHeaderHeight + 50;

    header.style.width = tableHeader.offsetWidth + "px";
    header.style.top = -tableHeaderHeight + "px";
    tableHeader.style.marginTop = tableHeaderHeight + "px";

    window.addEventListener("scroll", () => {
      if (window.scrollY >= trueHeaderOffsetTop) {
        header.classList.add("sticky");
        header.style.top = setScrollTopPost;
      } else {
        header.classList.remove("sticky");
        header.style.top = -tableHeaderHeight + "px";
      }
    });
  }
};
