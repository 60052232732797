// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  interstingFacts: $("#slider-intresting-facts")
};

sliders.interstingFacts.slick({
  dots: false,
  autoplay: true,
  arrows: true,
  pauseOnFocus: false,
  speed: 600,
  autoplaySpeed: 5000
});
