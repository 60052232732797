import { isMobile, isTablet, tableWrap } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import { fixedHeaderTable } from "./table";
import "babel-polyfill";

window.addEventListener("load", () => {
  new SiteHeader({
    setBodyPaddingTop: document
      .querySelector("body")
      .classList.contains("page-template-homepage")
      ? false
      : true
  });
  new FloatLabels();

  toggleMenu();
  if (!isTablet()) {
    fixedHeaderTable();
  }
  tableWrap();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
